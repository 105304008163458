import Amplify from '@aws-amplify/api';

// ****************************** Import CSS ******************************** //

import "./MentherWebfontsKit/MentherWebfontsKit.css"

import "./index.css";

// ************************** Add Top Nav & Footer ************************* //

import './nav-header-footer/nav-header-footer.js';
import './nav-header-footer/nav-header-footer.css';

// ****************************** Import Images ******************************** //

// ** HERO SECTION (6) ** //
import "./assets/menther-homepage-images/ml-color.jpg";
import "./assets/menther-homepage-images/ml-her.jpg";

import "./assets/menther-homepage-images/j-her-sm.jpg";
import "./assets/menther-homepage-images/ak-color-sm.jpg";

import "./assets/menther-homepage-images/ns-color-sm.jpg";
import "./assets/menther-homepage-images/ns-her-sm.jpg";

// ** HARNESS SECTION ** //
import "./assets/menther-logos/menther-app-icon-web.png";

// ** MENTHER SOCIAL ** //
import "./assets/icons/social/instagram.svg";
// import "./assets/icons/social/instagram-hover.svg";
import "./assets/icons/social/linkedin.svg";
// import "./assets/icons/social/linkedin-hover.svg";
import "./assets/icons/social/twitter.svg";
// import "./assets/icons/social/twitter-hover.svg";

// ** CAROUSEL SECTION ** //
import "./assets/menther-homepage-images/ak-color-lg.jpg";
import "./assets/menther-homepage-images/jackie-color.jpg";

// ** APP VERSION SECTION ** //
import "./assets/menther-logos/menther-logo-sm.png";
import "./assets/menther-homepage-images/iphone-menther.png";

// ****************************** Scripts ******************************** //

 // JavaScript to change colors of carousel numbers

// Change color of numbers when clicking on numbers

$(document).on('click', '#carouselNumbers li', function(){
   $('#carouselNumbers li').removeClass('selected');
   $(this).addClass('selected');
});

// Change color of numbers when clicking on caruosel arrows

$('#carouselHome').on('slide.bs.carousel', function(f) {

    let fromId = $(this).find('.carousel-item').eq(f.from).attr('id');
    console.log("fromSlide is: " +fromId);

    let toId = $(this).find('.carousel-item').eq(f.to).attr('id');
    console.log("toSlide is: " +toId);

    var currentIndex = $('div.active').index() + 1;
    console.log("currentIndex is: " +currentIndex);

    // When next button clicked

    if (currentIndex==1 && fromId=="slide1" && toId=="slide2") {
      console.log("currentIndex equals 1");
      $('#carouselNumbers li').removeClass('selected');
      $('#carouselNumbers li.slide2').addClass('selected');
    }

    if (currentIndex==2 && fromId=="slide2" && toId=="slide3") {
      console.log("currentIndex equals 2");
      $('#carouselNumbers li').removeClass('selected');
      $('#carouselNumbers li.slide3').addClass('selected');
    }

    if (currentIndex==3 && fromId=="slide3" && toId=="slide4") {
      console.log("currentIndex equals 3");
      $('#carouselNumbers li').removeClass('selected');
      $('#carouselNumbers li.slide4').addClass('selected');
    }

    if (currentIndex==4 && fromId=="slide4" && toId=="slide1") {
      console.log("currentIndex equals 4");
      $('#carouselNumbers li').removeClass('selected');
      $('#carouselNumbers li.slide1').addClass('selected');
    }

    // When previous button clicked

    if (currentIndex==1 && fromId=="slide1" && toId=="slide4") {
      console.log("currentIndex equals 1");
      $('#carouselNumbers li').removeClass('selected');
      $('#carouselNumbers li.slide4').addClass('selected');
    }

    if (currentIndex==2 && fromId=="slide2" && toId=="slide1") {
      console.log("currentIndex equals 2");
      $('#carouselNumbers li').removeClass('selected');
      $('#carouselNumbers li.slide1').addClass('selected');
    }

    if (currentIndex==3 && fromId=="slide3" && toId=="slide2") {
      console.log("currentIndex equals 3");
      $('#carouselNumbers li').removeClass('selected');
      $('#carouselNumbers li.slide2').addClass('selected');
    }

    if (currentIndex==4 && fromId=="slide4" && toId=="slide3") {
      console.log("currentIndex equals 4");
      $('#carouselNumbers li').removeClass('selected');
      $('#carouselNumbers li.slide3').addClass('selected');
    }

  });


// function hover(element) {
//   element.setAttribute('src', 'instagram.svg');
// }

// function unhover(element) {
//   element.setAttribute('src', 'instagram-hover.svg');
// }

// document.querySelector('#home-nav-button').classList.add('hidden')
