// *************** Custom Configuration Developemnt *****************//

export const configDev = {
  Auth: {
    Cognito: {
      region: 'us-east-2',
      userPoolClientId: '7haks79ns1qk5ra1543s1fjra4',
      userPoolId: 'us-east-2_pIJCr5WRS',
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-2:aab7c7c1-ac81-4ac7-8190-6c6fe96e28e5',
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: 'your_cognito_domain',
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: ['http://localhost:3000/'],
          redirectSignOut: ['http://localhost:3000/'],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  },
  Storage: {
    // S3: {
    //   bucket: 'mlnpprodmediastgstack-invideosbb6a0bcd-1jmikgs207cjo',
    //   region: 'us-east-2',
    //   identityPoolId: 'us-east-2:17d78573-840c-420e-b893-f86693a2cf81',
    // }
    S3: {
      bucket: 'menther-dev-media',
      region: 'us-east-2',
      identityPoolId: 'us-east-2:aab7c7c1-ac81-4ac7-8190-6c6fe96e28e5',
    },
  },
  API: {
    GraphQL: {
      endpoint: 'https://tktjecftubeavlw45hi6hk24na.appsync-api.us-east-2.amazonaws.com/graphql',
      region: 'us-east-2',
      defaultAuthMode: 'userPool'
      }
  }
};

