// *************** Custom Configuration Production *****************//

export const configProd = {
  Auth: {
    Cognito: {
      region: 'us-east-2',
      userPoolClientId: '268htebe048cra1n847g38podv',
      userPoolId: 'us-east-2_w35i0eNLw',
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-2:95d80a4d-72f1-4ccd-9ed0-545ad266e9ea',
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: 'your_cognito_domain',
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: ['http://localhost:3000/'],
          redirectSignOut: ['http://localhost:3000/'],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  },
  Storage: {
    S3: {
      bucket: 'menther-prod-media',
      region: 'us-east-2',
      identityPoolId: 'us-east-2:95d80a4d-72f1-4ccd-9ed0-545ad266e9ea',
    },
  },
  API: {
    GraphQL: {
      endpoint: 'https://tktjecftubeavlw45hi6hk24na.appsync-api.us-east-2.amazonaws.com/graphql',
      region: 'us-east-2',
      defaultAuthMode: 'userPool'
      }
  }
};





